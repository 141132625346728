import request from '@/plugins/axios'

// 手机查账号 提交
export function blastPhone(data) {
  return request({
    url: `/blastAccount`,
    method: 'post',
    data
  })
}

// 查询
export function blastPhonePage(current, limit, data) {
  return request({
    url: `/blastAccount/${current}/${limit}`,
    method: 'post',
    data
  })
}
