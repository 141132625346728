<template>
  <div class="account-number">
    <div class="account-left">
      <div>
        <el-form :model="submitForm" ref="submitForm">
          <el-form-item
            prop="uid"
            :rules="{
              required: true,
              message: '请输入uid'
            }"
          >
            <el-input
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="input-bottom"
              type="textarea"
              v-model="submitForm.uid"
              placeholder="请输入Telegram uid或Telegram账号，单次提交多个请用逗号间隔"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="input-bottom"
              type="textarea"
              v-model="submitForm.note"
              placeholder="备注"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="submitFun"
          :loading="loading"
        >
          立即提交
        </el-button>
      </div>
      <!-- <logPage
        title="提交手机号记录"
        ref="searchIndex2Log"
        height="calc(100% - 205px)"
        :logList="logList"
        showType="2"
        @selectLog="selectLog"
      /> -->
    </div>
    <div class="account-right">
      <div class="top">
        <el-input
          size="small"
          placeholder="Telegram uid"
          clearable
          v-model="searchForm.uid"
          style="width:220px"
        ></el-input>
        <el-input
          size="small"
          placeholder="Telegram 账号"
          clearable
          v-model="searchForm.uname"
          style="width:220px;margin-left:10px"
        ></el-input>
        <el-button
          type="primary"
          size="mini"
          style=" margin-left:10px"
          @click="searchFun"
          :loading="loading"
        >
          查询
        </el-button>
        <el-button
          class="el-icon-refresh-left"
          size="mini"
          style=" margin-left:10px"
          @click="refresh"
          :loading="loading"
        >
          刷新
        </el-button>
      </div>
      <div class="content" v-loading="loading">
        <el-table
          style="margin-bottom:10px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
          header-row-class-name="tableheader"
          :data="list"
          border
          :height="windowsHeight - 210"
        >
          <el-table-column prop="uid" label="Telegram uid" width="260">
            <template slot-scope="scope">
              <span>{{ scope.row.uid }}</span>
              <el-button
                type="text"
                style="margin-left:10px"
                v-if="scope.row.uid"
                @click="userDetail(2, scope.row.uid)"
              >
                手机号查询
              </el-button>
              <el-button
                type="text"
                v-if="scope.row.uid && userInfo.isYs == 0"
                @click="userDetail(1, scope.row.uid)"
              >
                归属地
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="uname" label="Telegram 账号"></el-table-column>

          <el-table-column prop="createTime" label="创建时间"></el-table-column>

          <el-table-column prop="tips" label="处理状态" width="160">
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.state === 1
                    ? 'primary'
                    : scope.row.state === 0
                    ? 'success'
                    : 'warning'
                "
                disable-transitions
              >
                {{
                  scope.row.state === 0
                    ? '处理完成'
                    : scope.row.state === 1
                    ? '已提交，处理中'
                    : '处理失败'
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="err" label="失败原因"></el-table-column>
          <el-table-column prop="note" label="备注"></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          :background="false"
          :sizesArr="[30, 50, 100]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <!--  -->
    <userDetail
      ref="userDetailDialog"
      :placeVisible="placeVisible"
      @closePlace="closePlace"
    />
  </div>
</template>

<script>
import { blastPhone, blastPhonePage } from '@/api/dropCheck/accountCheck'

// import { getLocalStorageFun, localStorageFun } from '@/plugins/logFunction'
export default {
  name:'CheckAccountCheck',
  data() {
    return {
      submitForm: {
        uid: '',
        note: ''
      },

      params: {},
      current: 1,
      limit: 30,
      total: 0,
      list: [],
      logList: [],
      searchForm: {
        uid: '',
        uname: ''
      },

      loading: false, //加载状态
      // 手机号、归属地
      placeVisible: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    this.getPage()
  },
  methods: {
    // 刷新
    refresh() {
      this.getPage()
    },
    // 查询
    searchFun() {
      this.current = 1
      this.getPage()
    },
    getPage() {
      this.loading = true
      blastPhonePage(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },
    // 手机号、归属地查询
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 关闭手机、归属地弹框
    closePlace() {
      this.placeVisible = false
    },
    submitFun() {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          blastPhone(this.submitForm).then(data => {
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
            this.current = 1

            this.submitForm.uid = ''
            this.submitForm.note = ''
            this.getPage()
            this.$nextTick(() => {
              this.$refs['submitForm'].clearValidate()
            })
          })
          // localStorageFun('telegramAccount', this.submitForm)
          // this.logList = getLocalStorageFun('telegramAccount')
        }
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    selectLog(row) {
      this.$refs.searchIndex2Log.selectIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
.account-number {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    .tips-text {
      position: absolute;
      top: 50%;
      font-size: 12px;
      font-weight: 300;
      color: #050505;
    }
    .input-bottom {
      margin-bottom: 10px;
    }
    .el-form-item {
      margin-bottom: 10px !important;
    }
    ::v-deep .el-form-item__error {
      top: 90% !important;
    }
    ::v-deep .el-input__inner {
      padding-right: 0;
    }
  }
  .account-right {
    width: calc(100% - 270px);
    height: 100%;
    // border: 1px solid #e5e5e5;

    padding-left: 5px;
    position: relative;
    overflow: hidden;
    .top {
      overflow: hidden;
      padding: 5px 10px;
      background: white;
      box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
      border-radius: 5px;
    }
    .content {
      margin-top: 10px;
      background: white;
      padding: 10px;
      box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
      border-radius: 5px;
    }
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
